@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

$basefont: "Poppins", sans-serif;
$black: #050505;
$white: #fff;
$grey: #646464;
$dummy: #bbb;
$cardBg: #ffffff3d;
$inputBg: #171616;
$border: #414141;
$basecolor: #08fafb;
$scrollColor: #464646;
$modalbg: rgba(217, 217, 217, 0.5);
$gradient: linear-gradient(
  90deg,
  rgba(255, 255, 255, 1) 17%,
  rgba(200, 199, 201, 1) 90%
);
$colorgradient: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0) 12%,
  rgb(20, 255, 255, 0.9) 58%,
  rgb(0, 255, 255) 90%
);
