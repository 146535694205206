@import "../../../assets/style/_var.scss";

.tokenclaim {
  &__headtitle {
    h3 {
      font-weight: 600;
      font-size: 30px;
      line-height: 24px;
      color: $white;
      margin-bottom: 30px;
    }
  }
  &__details {
   
    &__list {
      height: 260px;
      .ringLoader {
        display: flex;
        justify-content: center;
        align-items: center;
    height: 100%;
      }

      li {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        color: $white;
        font-weight: 300;
        margin-bottom: 16px;
        .rl_vale {
          font-weight: 500;
          width: 20%;
          @media (max-width:1440px){
            width: 18%;
          }
        }

        .data {
          width: auto;
          .vlue {
            margin-left: 10px;
          }
        }
        .sale_typ {
          width: 200px; text-transform: uppercase;
          @media (max-width:1440px){
            width: 125px;
          }
        }
        &:last-child{
          margin-bottom:0;
        }
      }
    }
  }

  
}

.revnueclaim{
  text-align: center; padding-left: 60px;
  @media (max-width:991px){
    padding-left: 0px; text-align: left; margin-top: 40px;
  }
  &__details{
    &__list{

      height: auto; overflow: unset;
    }
  }
}