@import "../../../assets/style/_var.scss";

.inputCustom_Style {
  label {
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    color: $white; font-family: $basefont;
    margin-bottom: 0px;
  }
  .ant-input {
    border-radius: 10px;
    border-width: 0; height: 30px; line-height: 30px;
    padding: 5px 15px; font-family: $basefont;
    background-color: $white;
    font-weight: 600; text-transform: uppercase;
    font-size: 14px; color: $grey;
    &:focus {
      box-shadow: unset;
    }
    &::placeholder {
      color: $dummy;
    }
  }
}
