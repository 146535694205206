.dashboard_page{
    width: 65%;
    @media (max-width:1600px){
        width: 65%; 
    }
    @media (max-width:1280px){
        width: 80%; 
    }
    @media (max-width:991px){
        width: 100%; 
    }
    .bottom_view{
        align-items: flex-end;
        margin-top: 75px;
        
    }
}