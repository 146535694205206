@import "../../../assets/style/_var.scss";

.connect_wallet {
  display: flex;
  flex: 1;
  height: auto;
  max-width:480px;
  @media (max-width:767px){
  max-width:340px;
  }
  .ant-modal-body {
    padding: 30px;
    @media (max-width:767px){
      padding: 20px 15px;
      }
  }
  .ant-modal-content {
    color: $white;
    border-radius: 10px; background: transparent linear-gradient(126deg, #a4a4a421 0%, #262525 100%) 0% 0% no-repeat padding-box; backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    .ant-modal-header {
      padding: 20px 30px; background-color: transparent; border-bottom: 1px solid $white;
      @media (max-width:767px){
        padding: 20px 15px;
        }
      .ant-modal-title {
        font-size: 20px;
        color: $white; text-transform: uppercase;
        font-weight: 400;
        font-family: $basefont;
      }
      .btn-close {
        background: url("../../../assets/images/icon_close_modal.svg") center
          no-repeat;
        outline: none;
        filter: invert(0.6);
        &:focus {
          outline: none;
          box-shadow: unset;
        }
      }
    }

    .ant-modal-close {
      color: $white;
    }
  }
}

.design_pop {
  ul {
    padding-left: 0;
    li {
      display: block;
      margin-bottom: 16px;
      .connect_btn {
        padding: 13px 20px;
        border: 2px solid rgb(208, 208, 208);
        border-radius: 13px;
        text-decoration: none;
        color: black;
        display: flex;
        align-items: center; cursor: pointer;
        font-weight: 600;
        background: linear-gradient(90deg, rgba(255,255,255,1) 17%, rgba(200,199,201,1) 90%);
        position: relative;
        width: 100%;
        font-size: 18px;
        text-align: left;
        transition: all ease 0.5s;
        img {
          width: 30px;
          margin-right: 20px;
        }
        
        
        
      }
      &:last-child {
        margin: 0;
      }
    }
  }

  
}
