@import "../../../assets/style/_var.scss";

.stakin_list_page {
  background: url("../../../assets/images/proposal_bg.jpg") no-repeat;
  background-size: cover;
  background-position: right;
  &::before {
    content: "";
    background: $black;
    opacity: 0.2;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .ant-empty-description {
    color: white;
}
  @media (max-width: 991px) {
    background-attachment: fixed;
  }
  .staking_Page {
    width: 100%;
    .titleSec {
      display: flex;
      align-items: center;
      @media (max-width: 991px) {
        display: block;
      }
      h2 {
        color: $basecolor;
        font-size: 30px;
        font-weight: 600;
        line-height: 36px;
        text-transform: uppercase;
        margin-bottom: 30px;
        @media (max-width: 1199px) {
          font-size: 20px;
          line-height: 28px;
        }
        @media (max-width: 991px) {
          margin-bottom: 15px;
        }
      }
    }
    &_detail{
    .ant-table{
      background-color: rgba(0, 0, 0, 0.4);
      color: $white; border-radius: 18px;
      .ant-table-thead{
        tr{
          th {
            background: $basecolor; border-bottom: 0;
            &:first-child{
              border-top-left-radius: 18px;
            }
            &:last-child{
              border-top-right-radius: 18px;
            }
          }
        }
      }
      .ant-table-tbody{
        tr{
          td {
            border-bottom: 1px solid $basecolor;
          }
          &:last-child{
            td {
              &:first-child{
                border-bottom-left-radius: 18px;
              }
              &:last-child{
                border-bottom-right-radius: 18px;
              }
            }
          }
          td.ant-table-cell-row-hover {
            background: $black !important;
          }
          
        }
      }
      // &-content {
      //   overflow: scroll hidden;
      // }
    }
    .ant-pagination{
      margin-top: 30px ;
      .ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link {
        border-radius: 30px !important; border: 1px solid $basecolor;
      }
      .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link {
        color: #fff;  background-color: $black;
        border-color: $basecolor;
      }
      .ant-pagination-item-active {
        background-color: transparent; border: 0;
        a {
          background-color: $basecolor; color: $black; border-radius: 30px !important;
        }
      }
    }
    .back_home{
      font-family: $basefont;
      padding-left:20px;
      padding-right:20px;
      height: 35px; 
      border: 0;
      border-radius: 10px;
      background: $white;
      font-size: 14px;
      color:$black;
      text-transform: uppercase;
      font-weight: 700;
      display: inline-flex;
      align-items: center;
      justify-content: center; box-shadow: unset;
      text-align: center;
      transition: all ease 0.5s;
      -webkit-transition: all ease 0.5s; margin-top: 15px;
      @media (max-width:991px) {
        margin-top: 20px;
      }
    &:hover, &:focus{
        background: $basecolor; transition: all ease 0.5s;
        -webkit-transition: all ease 0.5s; color: $black;
        box-shadow: 0px 0px 8px $basecolor; 
    }
    }
  }
  }
  /* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: $basecolor $white;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: $black;
  border-radius: 4px;
}
*::-webkit-scrollbar-thumb {
  background-color: $basecolor;
  border-radius: 10px;
  border: 2px solid $black;
  height: 3px;
  &:focus {
    background-color: $basecolor;
  }
}
}

.ant-table-tbody>tr.ant-table-row:hover>td, .ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: $black ;
}

.ant-popover {
  .ant-popover-title {
    color: $white;
  }
  .ant-popover-inner, .ant-popover-arrow-content, .ant-popover-arrow-content:before {
      background: transparent linear-gradient(126deg, #a4a4a421 0%, #262525 100%) 0% 0% no-repeat padding-box; backdrop-filter: blur(15px);
      -webkit-backdrop-filter: blur(15px);
  }
  .ant-popover-inner-content, .ant-popover-inner-content *{
    color: $white;
  }
}


