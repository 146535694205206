@import "../../../assets/style/_var.scss";

.stakecard {
    margin: 40px 15px;
  &__input {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border: 0;
    gap: 10px;
    @media (max-width: 1440px) {
    }
    .inputCustom_Style {
      // margin-right: 10px;
    }
    &__btn {
      background-color: transparent;
      padding: 0;
      color: $white;
      border: 0;
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 600;
    }
  }
  &__detail {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
    
    &__apy {
      h1 {
        font-size: 17px;
        font-weight: 600;
        color: $white;
        margin-bottom: 0;
      }
      p {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }

  &__infoIcon{
    font-size: 20px;
    margin-left: 5px;
    margin-right: -25px;
  }

}
