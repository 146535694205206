@import "../../../assets/style/_var.scss";

.social__icons{
        position: absolute;
        left: 30px;
        height: calc(100vh - 162px);
        justify-content: center;
        display: flex;
        align-items: center;
        @media (max-width:1600px){
            left: 15px;
        }
    
    ul{
        li{ padding: 3px 0;
            a{ cursor: pointer;
                &:hover {
                    background-color: $basecolor; 
                    display: block; border-radius: 10px; box-shadow: 0px 0px 5px $basecolor;
                    img{
                        filter: invert(1);
                    }
                }
                img{
                    width: 55px;
                    &.pint_icon{
                        height: 55px;
                        padding: 10px 0;
                    }
                }
            }
        }
    }
}