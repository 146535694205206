@import '../../../assets/style/_var.scss';
.nav_area{
  padding-left: 140px;
  padding-right: 140px;
  margin-top: 20px;
  @media (max-width:1600px){
    padding-left: 100px;
    padding-right: 100px;
  }
  @media (max-width:991px){
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width:767px){
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .flex_draw{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  .headerStyle{
      width: 100%;
      @media (max-width:991px){
        position: fixed;
        background-color: $inputBg;
        top: 0;
        width: 300px !important;
        height: 100%;
        left: -100%;
        transition: all ease-in-out 0.3s;
        opacity: 0;
        visibility: hidden;
        display: flex; z-index: 9;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 20px;
        padding-right: 20px;
      }
      &.mobileNav{
        left: 0;
        opacity: 1; 
        visibility: visible; 
        .social_sides{
          @media (max-width:991px){
            display: block; 
            .social__icons {
              left: 15px;
              position: absolute;
              height: auto;
              bottom: 5px;
              ul{
                display: flex; flex-wrap: wrap; justify-content: flex-start; 
                li{
                  margin: 0 8px;
                  a{
                    img{
                      width: 45px;
                      height: 45px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .social_sides{
        display: none;   
      }
        .ant-menu{
            background-color: transparent;
            border-bottom: 0;
            border-right: 0;
            flex: 1; padding: 0;
            display: flex;
            @media (max-width:991px){
                display: block; width: 100%;
            }
            .ant-menu-item{
                width: 33.33%; padding: 0;
                @media (max-width:991px){
                    width: 100%; margin: 15px 0;
                }
                &:active{
                    background: transparent;
                }
                &.ant-menu-item-selected, &.ant-menu-item-selected:focus{
                background-color: transparent;
                }
                a{
                    font-size: 14px; position: relative;
                    color: $white; font-weight: 300;
                    text-transform: uppercase; 
                    @media (max-width:991px){
                      display: block;
                    }
                    &:hover{
                        color: $basecolor;
                    }
                    &.selected{
                        &::after{
                            position: absolute;
                            content: "";
                            width: 100%;
                            height: 2px;
                            background-color: $white;
                            bottom: -5px;
                            left: 0;
                            display: block;
                            @media (max-width:991px){
                              bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-btn-primary.clrd_btn {
      @media (max-width:991px){
        position: absolute;
        right: 75px;
        top: 15px;
        z-index: 1;
      }
      
    }
  }
  }

.mobile_drawer{
    display: none; position: absolute; right: 15px; top: 20px; z-index: 1;
    @media (max-width:991px) {
      display: block;
    }
}
.menu_toggle {
    background: transparent;
    height: 30px;
    width: 39px;
    border-radius: 50%; cursor: pointer;
    top: -1px; padding: 0;  
    box-shadow: 0px 4px 7px rgb(9 13 20 / 10%);
    display: block; border: 0; position: relative;
    .wrap_icon{
        position: absolute;
        left: 0px;
        top: 2px;
    }
  .icon_left {
    transition-duration: 0.5s;
    position: absolute;
    height: 3px;
    width: 15px;
    top: 12px;
    background-color: $basecolor;
    left: 5px;
    &:hover {
      cursor: pointer;
    }
    &:before {
      transition-duration: 0.5s;
      position: absolute;
      width: 15px;
      height: 3px;
      background-color: $basecolor;
      content: "";
      top: -9px;
      left: 0;
    }
    &:after {
      transition-duration: 0.5s;
      position: absolute;
      width: 15px;
      height: 3px;
      background-color: $basecolor;
      content: "";
      top: 9px;  left: 0;
    }
  }
  .icon_right {
    transition-duration: 0.5s;
    position: absolute;
    height: 3px;
    width: 15px;
    top: 12px;
    background-color: $basecolor;
    left: 20px;
    &:before {
      transition-duration: 0.5s;
      position: absolute;
      width:15px;
      height: 3px;
      background-color: $basecolor;
      content: "";
      top: -9px;
      left: 0;
    }
    &:after {
      transition-duration: 0.5s;
      position: absolute;
      width: 15px;
      height: 3px;
        background-color: $basecolor;
     
      content: "";
      top: 9px;  left: 0;
    }
  }
  &.open{
    .icon_left {
      transition-duration: 0.5s;
      background: transparent; left: 0;
      &::before{
        transform: rotateZ(45deg) scaleX(1.4) translate(7px, -2px);
      }
      &::after{
        transform: rotateZ(-45deg) scaleX(1.4) translate(7px, 2px);
      }
    }
    .icon_right {
      transition-duration: 0.5s;
      background: transparent; left: 15px;
      &:before {
        transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 4px);
      }
      &:after {
        transform: rotateZ(45deg) scaleX(1.4) translate(-3px, -4px);
      }
    }
  }
}

