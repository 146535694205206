@import '../../../assets/style/_var.scss';

.ant-btn-primary, 
.ant-btn-primary{  
    font-family: $basefont;
    padding-left:20px;
    padding-right:20px;
    height: 30px; 
    border: 0;
    border-radius: 10px;
    background: $white;
    font-size: 14px;
    color:$black;
     text-transform: uppercase;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center; box-shadow: unset;
    text-align: center;
    transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
    &:hover, &:focus{
        background: $basecolor; transition: all ease 0.5s;
        -webkit-transition: all ease 0.5s; color: $black;
        box-shadow: 0px 0px 8px $basecolor; 
    }
}

.ant-btn-primary.clrd_btn{  
    font-family: $basefont;
    padding-left:20px;
    padding-right:20px;
    height: 40px; border: 1px solid $white;
    border-radius: 8px;
    background: $basecolor;
    font-size: 14px;
    color:$black; box-shadow: 0px 0px 8px $basecolor;
    min-width: 165px; z-index: 1;
    &:hover, &:focus{
        background: $gradient; transition: all ease 0.3s;
        -webkit-transition: all ease 0.5s; color: $black;
        box-shadow: 0px 0px 8px $white; border: 1px solid $basecolor; 
    }
}