@import "../../../assets/style/_var.scss";
.cardDetails {
  margin-top: 20px;
  margin-bottom: 50px;

  h4 {
    color: $white;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    @media (max-width: 1400px) {
      font-size: 14px;
    }
    span {
      font-size: 15px;
      font-weight: 200;
      line-height: 20px;
      margin-left: 15px;
      @media (max-width: 1400px) {
        font-size: 13px;
      }
    }
  }
  .mt {
    margin-top: 10px;
    @media (max-width: 1400px) {
      margin-top: 5px;
    }
  }
  .title {
    span {
      text-transform: uppercase;
    }
  }
  .proposelSec {
    max-width: 280px;
    @media (max-width: 1400px) {
      max-width: 340px;
    }
    @media (max-width: 767px) {
      max-width: 100%;
    }
    .ant-anchor-ink {
      max-width: 280px;
      @media (max-width: 1400px) {
        max-width: 340px;
      }
      @media (max-width: 767px) {
        max-width: 100%;
      }
      &::before {
        display: none;
      }
    }
    .linksec {
      color: $white;
      word-break: break-all;
      &::before {
        display: none;
      }
    }
  }
  .discriptionSec {
    max-width: 319px;
    @media (max-width: 1400px) {
      max-width: 340px;
    }
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  .commitedSec {
    h4 {
      color: $white;
      span {
        margin-left: 20px;
      }
    }
  }
  .viewdetailSec {
    display: flex;
    align-items: center;

    a {
      color: $white;
      border-bottom: 1px solid $white;
      font-size: 16px;
      font-weight: 300;
      line-height: 19px;
    }
  }
  .stakebutton {
    background-color: $white;
    border-radius: 10px;
    color: $black;
    border: none;
    margin-left: 30px;
    width: 88px;
    height: 27px;
    line-height: 1;
  }
}
.stakeModal {
  .ant-modal-content {
    border-radius: 20px;
    background: $modalbg;
    color: $white;
    .ant-modal-close-x {
      margin: 19px;
      svg {
        fill: $white;
        width: 34px;
        height: 34px;
        // background: url("");
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-modal-body {
      padding: 69px 60px 53px 90px;
      @media (max-width: 767px) {
        padding: 50px 30px 30px 30px;
      }
      .modal {
        h4 {
          color: $white;
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          margin: 0;
          @media (max-width: 992px) {
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
          }
          span {
            font-weight: 300;
            a {
              color: $white;
            }
          }
        }
        .modal-title {
          margin-bottom: 27px;
          span {
            margin-left: 39px;
          }
        }
        .modal-proposal {
          span {
            margin-left: 15px;
          }
        }
        .total-staked {
          margin-top: 5px;
        }
        .span-padding {
          margin-left: 5px;
        }
        .mb {
          margin-bottom: 27px;
          @media (max-width: 992px) {
            margin-bottom: 15px;
          }
        }
        .discription {
          p {
            font-size: 16px;
          }
        }
        .byCol {
          margin-top: 8px;
        }
      }
      .modalstakeButton {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 46px;
        @media (max-width: 992px) {
          margin-top: 26px;
        }
      }
    }
    .ant-modal-footer {
      display: none;
    }
  }
}
