@import "../../../assets/style/_var.scss";

.community_page {
  background: url("../../../assets/images/community_bg.jpg") no-repeat;
  background-size: cover;
  background-position: right;
  @media (max-width: 991px) {
    background-attachment: fixed;
    // &::before {
    //   content: "";
    //   background: black;
    //   opacity: 0.5;
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    // }
  }
  .communityPage {
    h1 {
      font-size: 80px;
      font-weight: 900;
      color: $basecolor;
      line-height: 77px;

      @media (max-width: 992px) {
        font-size: 60px;
        font-weight: 700;
        line-height: 50px;
      }
      @media (max-width: 480px) {
        font-size: 42px;
        font-weight: 600;
        line-height: 40px;
        margin-bottom: 10px;
      }
    }
    p {
      max-width: 363px;
      margin-bottom: 45px;
      @media (max-width: 480px) {
        margin-bottom: 20px;
      }
    }
    .Contactus {
      margin-left: 30px;
    }
  }
}
