@import '../../../assets/style/_var.scss';

.footer{
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    @media (max-width:991px){
        padding-top: 15px;
    padding-bottom: 15px;
    }
    &::after{
        position: absolute;
        content: "";
        background: $colorgradient;
        left: 0;
        width: 100%;
        height: 280px; bottom: 0;
        z-index: -1;
        @media (max-width:1400px){
            height: 210px;
        }
    }
    .ant-col{
        text-align: center;
    }
    .footer__list{
        display: flex;
        margin: 0 60px 0 130px; justify-content: space-between; justify-content: center;
        @media (max-width:1440px){
            margin: 0 60px 0 90px; 
        }
        @media (max-width:1199px){
            margin: 0 40px 0 90px; 
        }
        @media (max-width:991px){
            margin: 0 15px;  flex-wrap: wrap;
        }
        li{
            margin-left: 10px;
            margin-right: 10px;
            a{
                color: $black;
                font-size: 14px;
                text-transform: uppercase;
                transition: all .25s;
                font-weight: 700;
                @media (max-width:1199px){
                    font-size: 12px;
                }
                @media (max-width:991px){
                    font-size: 12px;
                }
                &:hover{
                    color: $black;
                }
            }
        }
    }
}
