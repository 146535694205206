@import "./assets/style/_var.scss";
html {
  height: 100%;
}
body {
  margin: 0;
  font-family: $basefont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: #050505;
  color: #fff;
  &.home {
    background: url(./assets/images/home_bg.jpg) center no-repeat;
    background-size: cover;
    @media (max-width: 991px) {
      background-attachment: fixed;
    }
  }
  @media (max-width: 991px) {
    background-attachment: fixed !important;
    // &::before {
    //     content: "";
    //     background: $black ;
    //     opacity: 0.4;
    //     position: absolute;
    //     width: 100%;
    //     height: 1600px;
    // }
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  .ant-layout-content {
    flex: 1 0 auto;
    position: relative;
    padding-left: 140px;
    height: calc(100vh - 155px);
    padding-right: 140px;
    display: flex;
    align-items: center;
    @media (max-width: 1600px) {
      padding-left: 100px;
      padding-right: 100px;
    }
    @media (max-width: 991px) {
      padding-left: 15px;
      padding-right: 15px;
      height: auto;
      padding-top: 85px;
      align-items: flex-start;
      .social__icons {
        display: none;
      }
    }
  }
}
.ant-card {
  color: $white;
  border-radius: 0;
  padding: 0px;
  background-color: transparent;
  // background: transparent linear-gradient(126deg, #a4a4a421 0%, #262525 100%) 0% 0% no-repeat padding-box;
  // backdrop-filter: blur(15px);
  // -webkit-backdrop-filter: blur(15px);
  // padding: 30px; border: 1px solid $border;
  .ant-card-body {
    padding: 0;
  }
}
// perfect scrollbar
// .scrollbar-container {
//   padding-right: 10px !important;
//   .ps__thumb-y {
//     background-color: $scrollColor !important;
//     width: 3px !important;
//   }
//   .ps__rail-y {
//     width: 7px !important;
//   }
// }
// .ps .ps__rail-x:hover,
// .ps .ps__rail-y:hover,
// .ps .ps__rail-x:focus,
// .ps .ps__rail-y:focus,
// .ps .ps__rail-x.ps--clicking,
// .ps .ps__rail-y.ps--clicking {
//   background: transparent !important;
//   border-radius: 8px;
//   width: 6px !important;
// }
// .ps__thumb-x {
//   background-color: $scrollColor !important;
//   height: 3px !important;
// }
// .ps__rail-y:hover > .ps__thumb-y,
// .ps__rail-y:focus > .ps__thumb-y,
// .ps__rail-y.ps--clicking .ps__thumb-y {
//   background-color: $scrollColor !important;
//   width: 3px !important;
// }
// .ps__rail-x:hover > .ps__thumb-x,
// .ps__rail-x:focus > .ps__thumb-x,
// .ps__rail-x.ps--clicking .ps__thumb-x {
//   background-color: $scrollColor !important;
//   height: 3px !important;
// }
// ::-webkit-scrollbar {
//   width: 0px !important;
//   background: transparent !important;
// }


/* ===== Scrollbar CSS ===== */
// /* Firefox */
// * {
//   scrollbar-width: auto;
//   scrollbar-color: $basecolor $white;
// }
// /* Chrome, Edge, and Safari */
// *::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
// }
// *::-webkit-scrollbar-track {
//   background: $black;
//   border-radius: 4px;
// }
// *::-webkit-scrollbar-thumb {
//   background-color: $basecolor;
//   border-radius: 10px;
//   border: 2px solid $black;
//   height: 3px;
//   &:focus {
//     background-color: $basecolor;
//   }
// }