@import "../../../assets/style/_var.scss";

.proposal_page {
  background: url("../../../assets/images/proposal_bg.jpg") no-repeat;
  background-size: cover;
  background-position: right;

  @media (max-width: 991px) {
    background-attachment: fixed;
  }
  
  .proposelPage {
    margin-top: 40px;
    @media (max-width: 1400px) {
      margin-top: 30px;
    }
    .titleSec {
      display: flex;
      align-items: center;
      @media (max-width: 991px) {
        display: block;
      }
      h2 {
        color: $basecolor;
        font-size: 30px;
        font-weight: 600;
        line-height: 36px;
        text-transform: uppercase;
        margin-bottom: 0;
        @media (max-width: 1199px) {
          font-size: 20px;
          line-height: 28px;
        }
        @media (max-width: 991px) {
          margin-bottom: 5px;
        }
      }
      .timer {
        display: flex;
        font-size: 30px;
        line-height: 36px;
        font-weight: 400;
        margin: 0;
        text-transform: uppercase;
        padding: 0 25px;
        @media (max-width: 1199px) {
          font-size: 20px;
          line-height: 28px;
        }
        @media (max-width: 991px) {
          padding: 0;
          margin-bottom: 10px;
        }
        span {
          font-weight: 600;
          margin-right: 10px;
        }
        .timer {
          display: flex;
          font-size: 30px;
          line-height: 36px;
          margin: 0;
          text-transform: uppercase;
          padding: 0 25px;
          span {
            font-weight: 700;
          }
          .mr {
            padding-right: 10px;
          }
        }
        .ant-statistic-content {
          span {
            color: $white;
          }
        }
      }
    }
  }
  .createmodalSec {
    .ant-modal-content {
      border-radius: 20px;
      background: $modalbg;
      color: $white;
      .ant-modal-close-x {
        margin: 19px;
        svg {
          fill: $white;
          width: 34px;
          height: 34px;
          // background: url("");
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ant-modal-body {
        padding: 96px 139px 54px 134px;
        @media (max-width: 767px) {
          padding: 46px 59px 54px 54px;
        }
        .ant-form-item {
          @media (max-width: 991px) {
            flex-direction: column;
            width: 100%;
          }
          .ant-radio-group {
            display: flex;
            justify-content: space-between;
            .ant-radio-button-wrapper:not(:first-child):before {
              display: none;
            }
            @media (max-width: 567px) {
              flex-direction: column;
            }
          }
        }
        .ant-form-item-label {
          flex-grow: 1;
          text-align: start;

          label {
            color: $white;
            font-size: 24px;
            font-weight: 700;
            line-height: 26px;
            white-space: nowrap;
            @media (max-width: 991px) {
              font-size: 18px;
              font-weight: 500;
            }
            &::after {
              display: none;
            }
          }
        }
        .formbutton {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 40px;
          .ant-form-item {
            margin-bottom: 0;
          }
          .ant-col-offset-4 {
            margin-left: 0;
          }
        }

        .modaltransparentButton {
          background: transparent;
          border: solid 2px $white;
          color: $white;
          font-size: 14px;
          border-radius: 10px;
          @media (max-width: 580px) {
            margin-bottom: 10px;
          }
        }
      }
    }

    .ant-modal-footer {
      display: none;
    }
    .ant-col-4 {
      max-width: 40%;
      @media (max-width: 992px) {
        max-width: 100%;
      }
    }
    .ant-col-14 {
      flex-direction: column;
      max-width: 100%;
    }
  }
}
